.pagination{
    display: flex;
    align-items: center;
}
.page{
    box-sizing: border-box;
    border: 1px solid #8c8c8c;
    color: #8c8c8c;
    padding: 4px 12px;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 4px;
}
.page__current {
    border: 1px solid #2f54eb;
    color: #2f54eb;
    font-weight: bold;
}